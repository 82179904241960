<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							class="border-top-0 pointer"
							@click="sortByField('year')">
							{{ translate('year') }}
							<sort field="year" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('retail_profit')">
							{{ translate('retail_profit') }}
							<sort field="retail_profit" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('fast_start_bonus')">
							{{ translate('fast_start_bonus') }}
							<sort field="fast_start_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('team_commission')">
							{{ translate('team_commission') }}
							<sort field="team_commission" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('leadership_matching_bonus')">
							{{ translate('leadership_matching_bonus') }}
							<sort field="leadership_matching_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('lifestyle_bonus')">
							{{ translate('lifestyle_bonus') }}
							<sort field="lifestyle_bonus" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('promotions')">
							{{ translate('promotions') }}
							<sort field="promotions" />
						</th>
						<th
							class="border-top-0 text-right pointer"
							@click="sortByField('total')">
							{{ translate('total') }}
							<sort field="total" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td
							class="align-middle">
							{{ item.id }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.retail_profit }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.fast_start_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.team_commission }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.leadership_matching_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.lifestyle_bonus }}
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.promotions }}
						</td>
						<td class="align-middle text-right">
							<b>{{ item.attributes.total }}</b>
						</td>
					</tr>
					<tr class="text-right">
						<td>
							<b />
						</td>
						<td class="text-right">
							<b>{{ totals.retail_profit }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.fast_start_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.team_commission }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.leadership_matching_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.lifestyle_bonus }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.promotions }}</b>
						</td>
						<td class="text-right">
							<b>{{ totals.total }}</b>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>
import {
	Report as CO,
	Grids,
	Tooltip,
	TransactionCategories,
} from '@/translations';
import Reports from '@/util/Report';
import sort from '@/components/SortArrow';

export default {
	name: 'TotalEarningsSummary',
	components: { sort },
	messages: [CO, Grids, Tooltip, TransactionCategories],
	data() {
		return {
			totalEarnings: new Reports(),
		};
	},
	computed: {
		loading() {
			return !!this.totalEarnings.data.loading;
		},
		errors() {
			return this.totalEarnings.data.errors;
		},
		pagination() {
			return this.totalEarnings.data.pagination;
		},
		data() {
			try {
				const { data } = this.totalEarnings.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		totals() {
			try {
				const { totals } = this.totalEarnings.data.response.data.meta;
				return totals;
			} catch (error) {
				return {};
			}
		},
	},
	mounted() {
		this.totalEarnings.getTotalEarningsDetail();
	},
};
</script>
